import Cookies from 'js-cookie';

const clearGoogtransCookies = () => {
  // Include both specific and general domains
  const domains = [window.location.hostname, '.surveywand.com', 'app.surveywand.com'];

  // Common path where cookies might be set; adjust as necessary based on your app's configuration
  const paths = ['/'];

  // Iterate over all domain and path combinations to ensure thorough cookie removal
  domains.forEach(domain => {
    paths.forEach(path => {
      Cookies.remove('googtrans', { path: path, domain: domain });
      // Log removal for debugging
      console.log(`Removed googtrans from ${domain}${path}`);
    });
  });

  // Additionally, attempt to remove without specifying the domain to catch any other cases
  Cookies.remove('googtrans', { path: '/' });
  console.log("Attempted blind removal of googtrans from /");
};

export const updateTargetLanguageCookie = (targetLanguage) => {
  const languageCodeMap = {
    "English": "en",
    "Spanish": "es",
    "French": "fr",
    "German": "de",
    "Dutch": "nl"
  };

  const targetLangCode = languageCodeMap[targetLanguage] || "en"; // Default target language if not found
  const baseLangCode = 'en'; // Assume base language is English for simplicity

  // Clear existing cookies first
  clearGoogtransCookies();

  // Set the cookie for both the specific subdomain and the general domain
  const cookieSettings = {
    path: '/',
    expires: 365, // Set for 1 year,
    sameSite: 'Lax',
  };


  // Set cookie for the general domain
  Cookies.set('googtrans', `/${baseLangCode}/${targetLangCode}`, { ...cookieSettings, domain: '.surveywand.com' });
  console.log(`Set googtrans for .surveywand.com: /${baseLangCode}/${targetLangCode}`);

  // Optionally set for specific subdomain if needed (usually not necessary if domain-wide setting works)
  // Cookies.set('googtrans', `/${baseLangCode}/${targetLangCode}`, { ...cookieSettings, domain: 'app.surveywand.com' });
};
